import SimpleCard from '../../../../ui/cards/SimpleCard';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/trends.scss';
import '../../../../styles/pages/checkoutTracking/recommendationItem.scss';
import statsLottie from '../../../../assets/json/lottie_animation/statsInfographicsLottie.json';
import setupTrackingLottie from '../../../../assets/json/lottie_animation/setup_tracking.json';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import CountdownTimer from '../../../../ui/timers/CountdownTimer';
import addDaysToDate from '../../../../lib/helper-functions/addDaysToDate';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import RecommendationItem from './RecommendationItem';
import { DAYS_TO_ADD } from '../../../../lib/constants/abstracts';
import get_recommendations_api_call from '../../../../lib/api/ai-tracker/get_recommendations_api_call';
import SelectableButton from '../../../../ui/buttons/SelectableButton';
import { creditCardSVG, giftCardSVG } from '../../../../lib/allCustomSVGs';
import Popup from '../../../../ui/popup/Popup';
import RecommendationsPayment from './RecommendationsPayment';
import set_recommendation_api_call from '../../../../lib/api/ai-tracker/set_recommendation_api_call';
import RecommendationsTrial from './RecommendationsTrial';
import get_subscriptions_api_call from '../../../../lib/api/ai-tracker/subscriptions/get_subscriptions_api_call';

interface Props {
  apiKey: string;
  isTrackable: boolean;
}

const Trends = ({ isTrackable, apiKey }: Props) => {
  const { t } = useTranslation();
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const [activeRecommendations, setActiveRecommendations] = useState<any[]>([]);
  const ai_tracker_configured_timestamp = useSelector(
    (state: RootState) => state.user.stats.ai_tracker_configured_timestamp
  );
  const [isTrialActive, setIsTrialActive] = useState<boolean>(false);
  const [trialRemainingDays, setTrialRemainingDays] = useState<number>(0);
  const [isPaymentActive, setIsPaymentActive] = useState<boolean>(false);
  const [isTrialEnded, setIsTrialEnded] = useState<boolean>(false);

  //Payment popup
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const togglePaymentPopupModal = () => {
    setIsPaymentPopupOpen(!isPaymentPopupOpen);
  };
  const [isTrialPopupOpen, setIsTrialPopupOpen] = useState(false);
  const toggleTrialPopupModal = () => {
    setIsTrialPopupOpen(!isTrialPopupOpen);
  };

  const futureDate = addDaysToDate(
    ai_tracker_configured_timestamp ? ai_tracker_configured_timestamp : '',
    DAYS_TO_ADD
  );

  const [isTimesUp, setIsTimesUp] = useState<boolean>(false);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');

  useEffect(() => {
    if (isTimesUp) {
      getSubscriptions();
    }
  }, [isTimesUp]);

  const calculateRemainingDays = (endDate: string): number => {
    const now: Date = new Date();
    const end: Date = new Date(endDate);

    const startOfToday: Date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );

    const differenceInTime: number = end.getTime() - startOfToday.getTime();

    const differenceInDays: number = Math.floor(
      differenceInTime / (1000 * 60 * 60 * 24)
    );

    return differenceInDays >= 0 ? differenceInDays : 0;
  };

  const getSubscriptions = async () => {
    try {
      const res = await get_subscriptions_api_call();

      if (res) {
        if (res?.length > 0) {
          const currentSubscription = res[res?.length - 1];
          const is_trial_active = currentSubscription?.is_trial_active;
          const is_active = currentSubscription?.is_active;

          if (is_trial_active === 0 && is_active === 0) {
            // trial expired and paid package inactive
            setIsTrialActive(false);
            setIsPaymentActive(false);
            setIsTrialEnded(true);
          }
          if (is_trial_active === 1 && is_active === 0) {
            // trial expired
            setIsPaymentActive(false);
            setIsTrialActive(false);
            setIsTrialEnded(true);
          }
          if (is_trial_active === 1 && is_active === 1) {
            // trial active and package not yet bought
            setIsTrialActive(true);
            setIsPaymentActive(false);
            const trialEndDate = currentSubscription?.end_date;
            const remainingDays = calculateRemainingDays(trialEndDate);
            setTrialRemainingDays(remainingDays);
          }
          if (is_trial_active === 0 && is_active === 1) {
            // trial expired and paid package active
            setIsPaymentActive(true);
            setIsTrialActive(false);
          }
        } else {
          //trial not started and package not activated
          setIsTrialPopupOpen(true);
          setIsTrialActive(false);
          setIsPaymentActive(false);
        }
        getRecommendations();
      } else {
      }
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  const getRecommendations = async () => {
    setLoading('pending');
    try {
      const res = await get_recommendations_api_call();

      if (res) {
        const { recommendations, active_recommendations } = res;

        if (recommendations?.length > 0) {
          const newRecommendations = recommendations?.map(
            (recommendation: any) => {
              return {
                ...recommendation,
                is_paused: 0,
                loadingStatus: 'success',
              };
            }
          );

          setRecommendations(newRecommendations);
        }

        if (active_recommendations?.length > 0) {
          const newActiveRecommendations = active_recommendations?.map(
            (recommendation: any) => {
              return {
                ...recommendation,
                loadingStatus: 'success',
              };
            }
          );

          setActiveRecommendations(newActiveRecommendations);
        }

        setLoading('success');
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setLoading('error');
    }
  };

  const setLoadingStatus = (id: string, status: string, isActive: boolean) => {
    const targetRecommendations = isActive
      ? activeRecommendations
      : recommendations;

    const newRecommendations = targetRecommendations?.map((item) =>
      item.id === id ? { ...item, loadingStatus: status } : item
    );

    if (isActive) {
      setActiveRecommendations(newRecommendations);
    } else {
      setRecommendations(newRecommendations);
    }
  };

  const onRecommendationSelect = (id: string) => {
    const filteredRecommendations = recommendations?.filter(
      (recommendation) => recommendation.id !== id
    );
    const selectedRecommendation = recommendations?.find(
      (recommendation) => recommendation.id === id
    );
    const updatedActiveRecommendations = [
      ...activeRecommendations,
      { ...selectedRecommendation, loadingStatus: 'initial' },
    ];
    setRecommendations(filteredRecommendations);
    setActiveRecommendations(updatedActiveRecommendations);
  };

  const onRecommendationUnselect = (id: string) => {
    const filteredActiveRecommendations = activeRecommendations?.filter(
      (recommendation) => recommendation.id !== id
    );
    const unselectedRecommendation = activeRecommendations?.find(
      (recommendation) => recommendation.id === id
    );
    const updatedRecommendations = [
      ...recommendations,
      { ...unselectedRecommendation, loadingStatus: 'initial' },
    ];
    setActiveRecommendations(filteredActiveRecommendations);
    setRecommendations(updatedRecommendations);
  };

  const onRecommendationPause = (id: string) => {
    const updatedRecommendations = activeRecommendations?.map((item) =>
      item.id === id ? { ...item, is_paused: 1 } : item
    );
    setActiveRecommendations(updatedRecommendations);
  };

  const onRecommendationResume = (id: string) => {
    const updatedRecommendations = activeRecommendations?.map((item) =>
      item.id === id ? { ...item, is_paused: 0 } : item
    );
    setActiveRecommendations(updatedRecommendations);
  };

  const setRecommendationsHandler = async (
    id: string,
    action: 'select' | 'unselect' | 'pause' | 'resume'
  ) => {
    try {
      setLoadingStatus(
        id,
        'pending',
        action === 'unselect' || action === 'pause' || action === 'resume'
      );

      const res = await set_recommendation_api_call(id, action);

      if (res && res.success) {
        if (action === 'select') {
          onRecommendationSelect(id);
        } else if (action === 'unselect') {
          onRecommendationUnselect(id);
        } else if (action === 'pause') {
          onRecommendationPause(id);
        } else if (action === 'resume') {
          onRecommendationResume(id);
        }
      } else {
        setLoadingStatus(
          id,
          'initial',
          action === 'unselect' || action === 'pause' || action === 'resume'
        );
      }
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setLoadingStatus(
        id,
        'initial',
        action === 'unselect' || action === 'pause' || action === 'resume'
      );
    }
  };

  const renderTrialButton = () => {
    if (isTrialEnded) return null;

    if (isPaymentActive && isTrialActive) {
      return (
        <SelectableButton
          icon={giftCardSVG}
          title={''}
          subTitle={t('TrialActive')}
          subTitle2={
            trialRemainingDays > 0
              ? `${trialRemainingDays} ${t('DaysLeft')}`
              : t('TrialExpired')
          }
          link=''
          onSelect={() => {
            if (isTrialActive) return;
            setIsTrialPopupOpen(true);
          }}
          isSelected={isPaymentActive && isTrialActive}
        />
      );
    } else if (!isPaymentActive && !isTrialActive) {
      return (
        <SelectableButton
          icon={giftCardSVG}
          title={''}
          subTitle={t('FreeTrial')}
          subTitle2={t('GetFreeRecommendationsFor14Days')}
          link=''
          onSelect={() => {
            if (isTrialActive) return;
            setIsTrialPopupOpen(true);
          }}
          isSelected={isPaymentActive && isTrialActive}
        />
      );
    } else if (!isPaymentActive && isTrialActive) {
      return (
        <SelectableButton
          icon={giftCardSVG}
          title={''}
          subTitle={t('TrialActive')}
          subTitle2={
            trialRemainingDays > 0
              ? `${trialRemainingDays} ${t('DaysLeft')}`
              : t('TrialExpired')
          }
          link=''
          onSelect={() => {
            if (isTrialActive) return;
            setIsTrialPopupOpen(true);
          }}
          isSelected={isPaymentActive && isTrialActive}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}

      <div className='flex-row-full-width-flex-wrap'>
        {isTrackable && !isTimesUp && (
          <SimpleCard>
            <div className='trends'>
              <h3>{t('TrendsText1')}</h3>
              <div className='trends-lottie'>
                <Lottie animationData={statsLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {!isTrackable && !isTimesUp && (
          <SimpleCard>
            <div className='trends'>
              <h3>{t('TrendsText2')}</h3>
              <div className='trends-lottie'>
                <Lottie animationData={setupTrackingLottie} loop={true} />
              </div>
            </div>
          </SimpleCard>
        )}
        {isTimesUp && loading === 'success' && (
          <div className='normalForm'>
            <div className='normalForm__top'>
              {isPaymentActive && !isTrialActive ? null : (
                <div className='shopPage__container1-alignLeft'>
                  <SelectableButton
                    icon={creditCardSVG}
                    title={''}
                    subTitle={t('PayWhatYouWant')}
                    subTitle2={t('UnlockAllRecommendations')}
                    link=''
                    onSelect={() => {
                      if (isPaymentActive && !isTrialActive) return;
                      setIsPaymentPopupOpen(true);
                    }}
                    isSelected={isPaymentActive && !isTrialActive}
                  />
                  {renderTrialButton()}
                </div>
              )}

              <div className='recommendations--all'>
                {/* suggested recommendations */}
                <div
                  style={{
                    marginTop:
                      isPaymentActive && !isTrialActive ? '0rem' : '3rem',
                  }}
                  className='recommendations'
                >
                  <h2>{t('Recommendations')}</h2>
                  <h3>{t('SuggestedRecommendationsText')}</h3>

                  {recommendations?.length === 0 && (
                    <div className='recommendationItem__container--none'>
                      <NoData title={t('NoRecommendations')} />
                    </div>
                  )}
                  {recommendations?.length > 0 && (
                    <div className='recommendationItem__container'>
                      {!isPaymentActive && !isTrialActive && (
                        <div className='recommendationItem__container--overlay'></div>
                      )}
                      {recommendations?.map((data: any, index: number) => (
                        <RecommendationItem
                          key={index}
                          data={data}
                          isActive={false}
                          setRecommendationsHandler={setRecommendationsHandler}
                          isDisabled={!isPaymentActive && !isTrialActive}
                        />
                      ))}
                    </div>
                  )}
                </div>

                {/* active recommendations */}
                <div className='recommendations'>
                  <h2> {t('ActiveRecommendations')}</h2>
                  <h3>{t('ActiveRecommendationsText')}</h3>
                  {activeRecommendations?.length === 0 && (
                    <div className='recommendationItem__container--none'>
                      <NoData title={t('NoActiveRecommendations')} />
                    </div>
                  )}
                  {activeRecommendations?.length > 0 && (
                    <div className='recommendationItem__container'>
                      {!isPaymentActive && !isTrialActive && (
                        <div className='recommendationItem__container--overlay'></div>
                      )}
                      {activeRecommendations?.map(
                        (data: any, index: number) => (
                          <RecommendationItem
                            key={index}
                            data={data}
                            isActive={true}
                            setRecommendationsHandler={
                              setRecommendationsHandler
                            }
                            isDisabled={!isPaymentActive && !isTrialActive}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <CountdownTimer
          prefix={t('StillCollectingData')}
          suffix={`${t('remaining')}.`}
          className='dashboardTab__item--left-subtitle'
          futureDate={futureDate}
          setIsTimesUp={setIsTimesUp}
          display={false}
        />
      </div>

      {/* ------payment popup start-------- */}
      <Popup
        styles={{
          backgroundColor: 'var(--sidebar-background-color)',
        }}
        setTrigger={togglePaymentPopupModal}
        trigger={isPaymentPopupOpen}
      >
        <RecommendationsPayment setIsPaymentPopupOpen={setIsPaymentPopupOpen} />
      </Popup>
      {/* ------payment popup end-------- */}
      {/* ------Trial popup start-------- */}
      <Popup
        styles={{
          backgroundColor: 'var(--sidebar-background-color)',
        }}
        setTrigger={toggleTrialPopupModal}
        trigger={isTrialPopupOpen}
      >
        <RecommendationsTrial
          setIsTrialPopupOpen={setIsTrialPopupOpen}
          getSubscriptions={getSubscriptions}
        />
      </Popup>
      {/* ------payment popup end-------- */}
    </>
  );
};

export default Trends;
