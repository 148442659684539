import React, { useState } from 'react';
import '../../styles/inputs/loginInput.scss';

type props = {
  icon: any;
  type?: string;
  required?: boolean;
  value: string;
  placeholder?: string;
  changeListeners?: ((e: React.ChangeEvent<HTMLInputElement>) => void)[];
  focusListeners?: ((e: React.FocusEvent) => void)[];
  id: string;
  classes?: string;
  pattern?: string;
  title?: string;
  autoFocus?: boolean;
};

const LoginInput = React.forwardRef<HTMLInputElement, props>(
  (
    {
      icon,
      value,
      changeListeners,
      focusListeners,
      placeholder = '',
      type = 'text',
      required,
      id,
      classes = '',
      pattern,
      title,
      autoFocus,
    }: props,
    ref
  ) => {
    const [innerValue, setValue] = useState<any>(value);
    const [focused, setFocused] = useState<boolean>(false);
    const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);

    function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
      setValue(event.target.value);
      changeListeners?.forEach((listener) => listener(event));
    }

    function handleFocusElement(event: React.FocusEvent<HTMLInputElement>) {
      focusListeners?.forEach((listener) => listener(event));
      setOnlyOnFocus(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      setOnlyOnFocus(false);
    };

    return (
      <>
        <div className='loginInput'>
          <div className='loginInput__left'>{icon}</div>
          <div className='loginInput__right'>
            <input
              className={`loginInput-input ${classes}`}
              style={{ fontFamily: 'var(--main-font-secondary)' }}
              ref={ref}
              id={id}
              type={type}
              autoFocus={autoFocus}
              value={innerValue}
              onChange={handleChangeValue}
              onFocus={handleFocusElement}
              onBlur={handleBlur}
              data-focused={focused.toString()}
              required={required}
              pattern={pattern}
              title={title}
            />
            <label htmlFor={id}>{placeholder}</label>
          </div>
        </div>

        <div className='loginInput-validation-text'>{onlyOnFocus && title}</div>
      </>
    );
  }
);

export default LoginInput;
