import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/checkoutTracking/recommendationsPayment.scss';
import Slider from '@mui/material/Slider';
import make_subscriptions_api_call from '../../../../lib/api/ai-tracker/subscriptions/make_subscriptions_api_call';
import { useState } from 'react';

type Props = {
  setIsPaymentPopupOpen: (value: boolean) => void;
};

const RecommendationsPayment = ({ setIsPaymentPopupOpen }: Props) => {
  const { t } = useTranslation();
  const [paymentValue, setPaymentValue] = useState<number>(65);

  const [loading, setLoading] = useState<'pending' | 'initial'>('initial');

  const onMakeSubscription = async () => {
    setLoading('pending');
    try {
      const res = await make_subscriptions_api_call(paymentValue, false);

      if (res) {
        setLoading('initial');
        setIsPaymentPopupOpen(false);
        if (
          res?.payment_details?.payment_details?._links?.checkout?.href &&
          typeof res?.payment_details?.payment_details?._links?.checkout
            ?.href === 'string'
        ) {
          window.location.href =
            res?.payment_details?.payment_details?._links?.checkout?.href;
        }
        // redirect to payment page but can go back
        // window.location.href = res.url;

        // redirect to payment page but cannot go back
        //window.location.replace(res.url);
      } else {
        setLoading('initial');
        setIsPaymentPopupOpen(false);
      }
    } catch (error) {
      console.error('Error making subscriptions:', error);
      setLoading('initial');
      setIsPaymentPopupOpen(false);
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setPaymentValue(newValue as number);
  };

  return (
    <div className='recommendationsPayment'>
      <div className='recommendationsPayment__title'>
        <h2>{t('PayWhatYouWant')}</h2>
        <span>{t('PayWhatYouWant2')}</span>
      </div>

      {/* make this amount label to an input to edit the number do it in the future */}
      <h2 className='recommendationsPayment__amount'>{'€' + paymentValue}</h2>
      <div className='recommendationsPayment__input'>
        {/*   <Slider
          aria-label='Restricted values'
          defaultValue={paymentValue}
          onChange={handleChange}
          step={5}
          valueLabelDisplay='auto'
          marks={marks}
          min={5}
          max={25}
          className={'recommendationsPayment__input--slider'}
        /> */}

        <Slider
          defaultValue={paymentValue}
          onChange={handleChange}
          valueLabelDisplay='auto'
          min={15}
          max={500}
        />
      </div>
      <div
        className='recommendationsPayment__button'
        onClick={onMakeSubscription}
      >
        {loading === 'pending' ? t('Loading...') : t('Pay')}
      </div>
    </div>
  );
};

export default RecommendationsPayment;
